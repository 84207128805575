/*
* Name        : DF Client Slider
* Version     : 1.0.0
* Author      : Deep Fried
* Author URL  : https://deepfried.com
* Description : Adds sliders to client list on homepage
*/


import { $ } from './_var';

export default function() {   
    
	// slick.js slideshows
	var slideshowsLoaded = false;
	// function slideshows() {
		// slideshows
		if ($('.client-slideshow').length) {

			var lazyMethod = 'progressive';
			if ($('html').is('.touch-events')) {
				lazyMethod = 'ondemand';
			}

			$('.client-slideshow').each(function() {
				var slideshow = $(this);
				var dotStyle = true;
				// var slideSpeed = 6500;
				var slidePlay = true;
				var slideFade = false;

				// if ($(this).is('.mission-slideshow')) {
				// 	slideFade = true;
				// 	dotStyle = false;
				// 	slideSpeed = 4000;
				// } else if ($(this).is('.careers-slideshow')) {
				// 	slideFade = true;
				// }

				slideshow.not('.slick-initialized').slick({
					dots: dotStyle,
					autoplaySpeed: false,
					pauseOnHover: true,
					infinite: true,
					speed: 6500,
					autoplay: false, // see slideplay "autoplay" alternate below
					fade: slideFade,
					cssEase: 'linear',
					arrows: false,
					lazyLoad: lazyMethod,
					focusOnSelect: true,
					rows: 0, // version div wrap fix
				}).on('click', function() {
					if (!iOSSafari) $(this).find('.slick-list').focus();
				});

				// trigger "autoplay" here (instead of slick autoplay) for best pause/play
				if (slidePlay) {
					slideshow.slick('slickPlay');

					// pause on interaction intent
					slideshow.find('logo, profile').hover(function() {
						slideshow.slick('slickPause');
					}, function() {
						slideshow.slick('slickPlay');
					});
				}
			});

			responsive(false);
		}

		slideshowsLoaded = true;

	// } END function slideshows()

}

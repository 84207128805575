/*
 * Name        : DFA Module
 * Version     : 0.3.4
 * Author      : Deep Fried
 * Author URL  : https://deepfried.com
 * Description : Establish some constants for use inside other modules.
*/

const $ = jQuery;
const $document = $(document);
const $window = $(window);
const $html = $('html');
const $body = $('body');
const $htmlAndBody = $html.add($body);

export { $, $document, $window, $html, $body, $htmlAndBody };

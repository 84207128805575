/*
* Name        : DF Text Cycle
* Version     : 1.0.0
* Author      : Deep Fried
* Author URL  : https://deepfried.com
* Description : Adds text cycle animation to intro text
*/


import { $ } from './_var';

export default function() {   
    const words = $('#intro-text svg');
    words.hide();
	(function cyclebox(wordMax) {
		words.eq(wordMax).show(0, function() {
			$(this).addClass('show');
		}).delay(transTime*5).show(0, function() {
			$(this).addClass('fade');
		}).delay(transTime/2).hide(0, function () {
			$(this).removeClass('show fade');
			cyclebox(wordMax + 1 < words.length ? wordMax + 1 : 0);
		});
	}(0));
}

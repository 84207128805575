/*
* Name        : DF Hero Flash Quote
* Version     : 1.0.0
* Author      : Deep Fried
* Author URL  : https://deepfried.com
* Description : Adds hero flash quote fade in functionality
*/


const html = document.querySelector('html');

function hideHeroFlashQuote() {
    if (!html.classList.contains('hero-flash-quote--is-hidden')) {
        html.classList.add('hero-flash-quote--is-hidden');
    }
}

export default function () {
    const body = document.querySelector('body');
    
    if (body.classList.contains('home')) {
        setTimeout(hideHeroFlashQuote, 4500);
    } else {
        hideHeroFlashQuote();
    }
};

/*
 * Name        : DFA Text Scroller
 * Version     : 0.1.0
 * Author      : Deep Fried Advertising
 * Author URL  : https://deepfried.com
 * Description : Changes text based on scroll position
 * Website     : http://textillate.js.org
*/

import { $ } from './_var';

const $selectedText          = $('.df-text-scroller');
const $selectedTextUnderline = $('.df-text-scroller__underline');


export default function() {
    if (0 < $selectedText.length) {
        const textResizeObserver = new ResizeObserver(function(entries) {
            // Access the first element in entries array
            let rect = entries[0].contentRect;
        
            // Current width
            let selectedTextBoxWidth = rect.width;
            
            // Animate underline as text width changes
            $selectedTextUnderline.animate({ width: selectedTextBoxWidth * .95 }, 150);
        });
        
        $selectedText.textillate({
            selector: '.df-text-scroller__items',
			loop: true,
			minDisplayTime: 1500,
			initialDelay: 0,
			autoStart: true,
			in: {
                effect: 'fadeIn',
            	delay: 0,
            	sync: true,
            },
            out: {
                effect: 'fadeOut',
            	delay: 0,
            	sync: true,
            },
            type: 'word',
        });
        
        // start observing for resize
        textResizeObserver.observe(document.querySelector('.df-text-scroller'));
    }
};

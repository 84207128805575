import './_polyfills';

import setupHeroFlashQuote from './_heroFlashQuote';
import setupIntroTextCycle from './_introTextCycle';
import setupTextScroller from './_textScroller';
import setupClientSlider from './_clientSlider';
import setupCaseStudyFilter from './_caseStudyFilter';

jQuery( document ).ready( ( $ ) => {
    setupHeroFlashQuote();
    setupIntroTextCycle();
    setupTextScroller();
    setupClientSlider();
    setupCaseStudyFilter();
});

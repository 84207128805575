/*
* Name        : DF Case Study Filter
* Version     : 1.0.0
* Author      : Deep Fried
* Author URL  : https://deepfried.com
* Description : Adds functionality for case study filter
*/

const filterNav    = Array.from(document.querySelectorAll('.df-case-study-filters__nav button'));
const filterGroups = Array.from(document.querySelectorAll('.df-case-study-filters__group'));
const filterGrid   = document.querySelector('#filtered-grid');
const transTime    = 500;

// Toggles selected category in filter nav
function toggleFilterSelected() {
    filterNav.forEach((item) => {        
        // Remove selected class from siblings
        if (this !== item) {
            item.classList.remove('selected');
        }
    });

    // Add selected class to this item
    if (!this.classList.contains('selected')) {
        this.classList.add('selected');
    }
}

// Toggles selected category in filter nav
function resetFilter() {
    const isAllButton = 'all' === this.dataset.filterGroupTarget;
    
    if (!isAllButton) {
        return;
    }
    
    filterNav.forEach((item) => {
        const target = item.dataset.filterGroupTarget;

        // Remove category IDs from filter
        if ('all' !== target) {
            filterGrid.setAttribute('data-' + target, '');
        }
    });
    
    // Remove selected class from all category buttons
    filterGroups.forEach((group) => {
        const buttons = Array.from(group.querySelectorAll('button'));
        buttons.forEach((button) => {
            button.classList.remove('selected');
        });
    });
    
    // Sort grid to reset
    sortGrid();
}

// Toggles category group when selected in filter nav
function toggleFilterGroup() {
    const filterGroupTarget = this.dataset.filterGroupTarget;
    // const filterGroups      = Array.from(document.querySelectorAll('.df-case-study-filters__group'));

    filterGroups.forEach((group) => {
        if (filterGroupTarget === group.dataset.filterGroup) {
            group.classList.add('is-active');
        } else {
            group.classList.remove('is-active');
        }
    });
}

// Toggles selected category in filter nav
function toggleCategorySelected() {
    const parentGroup    = this.parentNode.parentNode;
    const buttons = Array.from(parentGroup.querySelectorAll('button'));
    const parentCategory = 'data-' + parentGroup.dataset.filterGroup;

    // Reset selected class
    buttons.forEach((item) => {
        if (this !== item) {
            item.classList.remove('selected');
        }
    });
    
    if (!this.classList.contains('selected')) {
        this.classList.add('selected');
        filterGrid.setAttribute(parentCategory, this.dataset.categoryId); // Add category ID to filter
    } else {    
        this.classList.remove('selected');
        filterGrid.setAttribute(parentCategory, ''); // Remove category ID from filter
    }
}

function sortGrid() {
    if (!filterGrid) {
        return;
    }
    
    const filterGridItems   = Array.from(filterGrid.querySelectorAll('.grid-item'));
    filterGrid.classList.add('in-motion');
    
    // Filtering happens here...
    setTimeout(function() {
        filterGridItems.forEach((item) => {
            // Hide all items   
            item.style.display = 'none';
            
            // Check if there is a matching value
            const itemAttributeMatches = Array.from(item.attributes).filter((attr) => {
                const attrName = attr.nodeName;
                const attrVal  = attr.nodeValue;
                const isNotFilterAttribute = attrName === 'class'
                    && attrName === 'href'
                    && attrName === 'style'
                    && attrName === 'data-cats'
                    && attrName === 'data-sort';

                // Return if this attribute is not filter attribute
                if (isNotFilterAttribute) {
                    return;
                }

                const matchingValue = attrVal
                    .split(',') // Convert to array
                    .find(val => 0 < val.length && val === filterGrid.getAttribute(attrName)); // Checks against current filter value
                return 'undefined' !== typeof matchingValue; // Returns true if defined and match was found
            });

            // Convert datasets to object
            const filterGridData = {...filterGrid.dataset};
            
            // Get array of dataset values
            const filterGridDataValues = Object.values(filterGridData);
            
            // Filter out empty values
            const filterGridDataArray = filterGridDataValues.filter(item => 0 < item.length && 'undefined' !== typeof item);

            // If multiple values exist, all must match for item to display
            if (filterGridDataArray.length === itemAttributeMatches.length) {
                item.style.removeProperty( 'display' );
            }
        });
    }, transTime/2);
    
    // Unhide to show the filtered items
    setTimeout(function() {
        filterGrid.classList.remove('in-motion');
    }, transTime);

    const $header = $('header');
    headerHeight = $header.outerHeight();
    
    // .fall-in affects landing position; check for .seen for positioning
    if ($('.df-case-study-filters.fall-in').is('.seen')) {
        $htmlBody.stop().animate({scrollTop: $('.df-case-study-filters').offset().top - headerHeight}, transTime);
    } else {
        $htmlBody.stop().animate({scrollTop: $('.df-case-study-filters').offset().top - headerHeight*2}, transTime);
    }
}

export default function () {
    // Filter Nav
    filterNav.forEach(item => item.addEventListener('click', toggleFilterGroup));
    filterNav.forEach(item => item.addEventListener('click', toggleFilterSelected));
    filterNav.forEach(item => item.addEventListener('click', resetFilter));

    filterGroups.forEach((group) => {
        const buttons = Array.from(group.querySelectorAll('button'));
        buttons.forEach((button) => {
            button.addEventListener('click', toggleCategorySelected);
            button.addEventListener('click', sortGrid);
        });
    });
};
